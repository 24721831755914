const months = [
	"January",
	"February",
	"March",
	"April",
	"May",
	"June",
	"July",
	"August",
	"September",
	"October",
	"November",
	"December",
];

const prettyDate = dateString => {
	const date = new Date(dateString);

	const day = ordinalSuffixFor(date.getDate());
	const month = months[date.getMonth()];
	const year = date.getFullYear();

	return `${day} ${month}, ${year}`;
};

const ordinalSuffixFor = i => {
	var j = i % 10,
		k = i % 100;
	if (j === 1 && k !== 11) {
		return i + "st";
	}
	if (j === 2 && k !== 12) {
		return i + "nd";
	}
	if (j === 3 && k !== 13) {
		return i + "rd";
	}
	return i + "th";
};

export default prettyDate;
