import * as React from "react";
import { graphql, Link } from "gatsby";
import reactHtmlParser from "react-html-parser";

import Layout from "../components/layout";
import SEO from "../components/seo";
import addStylesToContent from "../addStylesToContent";
import SeriesIntro from "../components/UI/SeriesNote";
import prettyDate from "../prettyDate";
import transformCustomElements from "../transformCustomElements";
import classNames from "classnames";
import { clientSideOnly, getDocument } from "../gatsbySafe";
import BookmarkThisPage from "../components/UI/BookmarkThisPage";

const updateArticleProgress = articleSlug => {
	const lastArticleElement = getDocument().querySelector(
		"article > *:last-child"
	);
	if (!lastArticleElement) {
		return;
	}
	const lastArticleElementBottom = lastArticleElement.getBoundingClientRect()
		.bottom;
	const bottomOfScreen = window.screenY + window.innerHeight;
	const progress = (bottomOfScreen / lastArticleElementBottom) * 100;

	const current = localStorage.getItem(`articleProgress:${articleSlug}`);
	if (progress > current) {
		localStorage.setItem(`articleProgress:${articleSlug}`, progress);
	}
};

const Article = props => {
	const {
		html,
		frontmatter: { title, date, seriesName, seriesPart, summary },
	} = props.data.markdownRemark;

	const parsedHtml = <>{reactHtmlParser(html)}</>;
	const translatedHtml = transformCustomElements(parsedHtml);
	const articleContents = addStylesToContent(translatedHtml);

	const articleSlug = React.useMemo(() => {
		const pathname =
			typeof window !== "undefined"
				? window.location.pathname
				: "/cookies";
		return pathname.split("/").filter(x => !!x)[1];
	}, []);

	React.useEffect(() => {
		localStorage.setItem(
			`articleLastRead:${articleSlug}`,
			new Date().toISOString()
		);
	}, [articleSlug]);

	// Update article reading progress on scroll
	React.useEffect(() => {
		clientSideOnly(() => {
			window.addEventListener(
				"scroll",
				() => {
					updateArticleProgress(articleSlug);
				},
				false
			);
		});
	}, [articleSlug]);

	// A few articles might be too short to have a scrollbar, so
	// do it once on load as well
	React.useEffect(() => {
		clientSideOnly(() => updateArticleProgress(articleSlug));
	}, []);

	return (
		<Layout>
			<SEO title={title} summary={summary} />
			<div className="container mx-auto px-6">
				<BookmarkThisPage
					phraseFn={command =>
						`Press ${command} to bookmark this page.`
					}
					className={classNames({
						"inline-block w-fit text-sm": true,
						"text-gray-700": true,
						"dark:text-gray-300 ": true,
					})}
				/>
				<h1
					className={classNames({
						"text-3xl": true,
						"md:text-4xl mt-4": true,
					})}
				>
					{title}
				</h1>
				<div className="text-sm mt-2 text-gray-600 dark:text-gray-300">
					Published {prettyDate(date)}
				</div>
				<SeriesIntro seriesName={seriesName} seriesPart={seriesPart} />

				<article>{articleContents}</article>

				<BookmarkThisPage
					phraseFn={command =>
						`If this article was helpful, why not press ${command} to bookmark this page?`
					}
					className={classNames({
						"inline-block w-fit mt-4 px-3 py-2": true,
						"border-l-2 bg-gray-100 border-teal-700 text-teal-700": true,
						"dark:bg-gray-700 dark:border-teal-600 dark:text-teal-300 ": true,
					})}
				/>
				<div className="mt-3">
					<Link
						to="/article/standard-disclaimer"
						className="link text-sm"
					>
						Disclaimer
					</Link>
				</div>
				{/* TODO: Series outro brings you to next part in series */}
			</div>
		</Layout>
	);
};

export const query = graphql`
	query($pageName: String!) {
		markdownRemark(fields: { pageName: { eq: $pageName } }) {
			html
			frontmatter {
				title
				summary
				tags
				date
				seriesName
				seriesPart
			}
		}
	}
`;

export default Article;
