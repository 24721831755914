import * as React from "react";
import { Link, useStaticQuery, graphql } from "gatsby";

const SeriesIntro = ({ seriesName, seriesPart }) => {
	const data = useStaticQuery(graphql`
		query {
			allMarkdownRemark(
				filter: { frontmatter: { seriesName: { ne: "" } } }
			) {
				edges {
					node {
						frontmatter {
							seriesName
							seriesPart
							title
							hidden
						}
						fields {
							pageName
						}
					}
				}
			}
		}
	`);

	if (!seriesName) {
		return null;
	}

	const seriesPosts = data.allMarkdownRemark.edges
		.map(x => ({
			...x.node.frontmatter,
			...x.node.fields,
		}))
		.filter(
			x => x.seriesName === seriesName && !x.hidden && x.seriesPart > 0
		)
		.sort((a, b) => (a.seriesPart > b.seriesPart ? 1 : -1));

	return (
		<div className="max-w-lg mt-6 border-l-2 bg-gray-100 dark:bg-gray-700 text-gray-500 dark:text-gray-100 text-sm border-gray-300 dark:border-gray-500 px-4 py-2">
			<p className="mb-2">This article is part of a series:</p>
			<ol className="list-decimal list-inside mr-2">
				{seriesPosts.map(x => {
					if (x.seriesPart === seriesPart) {
						return <li key={x.seriesPart}>{x.title}</li>;
					} else {
						return (
							<li key={x.seriesPart}>
								<Link
									className="text-teal-500 underline"
									to={`/article/${x.pageName}/`}
								>
									{x.title}
								</Link>
							</li>
						);
					}
				})}
			</ol>
		</div>
	);
};

export default SeriesIntro;
