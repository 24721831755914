import React from "react";

const RelatedArticle = ({ children }) => {
	// Render related article
	return null;
};

const typeToClassMap = {
	relatedarticle: RelatedArticle,
};

const transformCustomElements = element => {
	if (!element.props) {
		return element;
	}

	const children = React.Children.map(element.props.children, x =>
		transformCustomElements(x)
	);

	const CustomType = typeToClassMap[element.type];

	if (!!CustomType) {
		return React.createElement(CustomType, element.props, children);
	}

	return React.cloneElement(element, element.props, children);
};

export default transformCustomElements;
