import React, { useState, useMemo } from "react";
import { getNavigator } from "../../gatsbySafe";
import { UAParser } from "ua-parser-js";

const BookmarkThisPage = ({ phraseFn, className = "" }) => {
	const userAgent = getNavigator().userAgent;
	const command = useMemo(() => {
		const os = new UAParser(userAgent).getOS();
		return os && os.name === "Mac OS" ? "Command-D" : "Ctrl-D";
	}, [userAgent]);

	return (
		<div className={className}>
			<BookmarkIcon className="w-4 h-4 inline mr-1 mb-1 stroke-current" />
			<span>{phraseFn(command)}</span>
		</div>
	);
};

const BookmarkIcon = ({ className }) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		className={className}
		fill="none"
		viewBox="0 0 24 24"
		stroke="currentColor"
	>
		<path
			strokeLinecap="round"
			strokeLinejoin="round"
			strokeWidth={2}
			d="M5 5a2 2 0 012-2h10a2 2 0 012 2v16l-7-3.5L5 21V5z"
		/>
	</svg>
);

export default BookmarkThisPage;
